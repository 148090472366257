import Report from "./pages/report/Report";
import {
  createBrowserRouter,
  RouterProvider,
} from 'react-router-dom'
import HomePage from "./Home";
import BloodTest from "./pages/bloodtest/BloodTest";
import XRayResult from "./pages/xray/XRayResult";

const router = createBrowserRouter([
  {
    path: '/',
    element: <HomePage />,
  },
    {
        path: '/report/:reportId',
        element: <Report />,
    },
  {
    path: '/analysis/:bloodTestId',
    element: <BloodTest />,
  },
  {
    path: '/xray/:xrayId',
    element: <XRayResult />,
  },
    // 404 page
  {
    path: '*',
    element: <h1>404 Not Found</h1>,
  },
]);



function App() {
  return (
      <RouterProvider router={router} />
  );
}

export default App;
