import React, { useEffect, useState } from 'react';
import {message, Result, Spin} from "antd";
import { useParams } from "react-router-dom";
import './XRayResult.css';

const getBloodTestData = async (reportId, setLoading) => {
    setLoading(true);
    const response = await fetch(`https://api.fluxivo.com/api/v1/xray/analysis/${reportId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        }
    });
    const data = await response.json();
    if (data.message) {
        setLoading(false);
        return message.error(data.message);
    }
    if (data?.data?.processing) {
        setLoading(false);
        return message.info('Analiz işlemi devam ediyor. Lütfen daha sonra tekrar deneyin.');
    }
    setLoading(false);
    return data;
}

const XRayResult = () => {
    const [loading, setLoading] = useState(true);
    const [xRayResult, setXRayResult] = useState([]);
    const [error, setError] = useState(null);

    const { xrayId } = useParams();

    useEffect(() => {
        if (xrayId) {
            getBloodTestData(xrayId, setLoading).then(data => {
                if(data.success === false) {
                    setLoading(false);
                    setError('X-Ray sonucu bulunamadı');
                    return;
                }
                setXRayResult(data?.data);
            });
        }
    }, [xrayId]);

    return (

        <>
            <Spin fullscreen spinning={loading}/>
            <div className="card">

                {error ? <>
                    <Result
                        status={"error"}
                        title="Röntgen Analizi Sonucu Bulunamadı"
                        subTitle="Lütfen adresi kontrol edin ya da daha sonra tekrar deneyin."
                    />
                </> : <>
                    <img src={require("../../assets/logo512.png")} alt="logo" style={{
                        width: 100,
                        height: 100,
                        margin: '0 auto',
                        marginTop: 32,
                        display: 'block'
                    }}/>
                    <div className="card-content" dangerouslySetInnerHTML={{__html: xRayResult?.data?.result || {}}}/>
                </>}


            </div>
        </>
    );
};

export default XRayResult;
