import React, {useEffect, useState} from 'react';
import {Card, message, Spin} from "antd";
import {useParams} from "react-router-dom";

import './BloodTestPage.css';


const getBloodTestData = async (reportId, setLoading) => {
    setLoading(true)
    const response = await fetch(`https://api.fluxivo.com/api/v1/bloodtest/analysis/${reportId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        }

    });
    const data = await response.json();
    if(data.message) {
        return message.error(data.message);
    }
    if(data?.data?.processing) {
        return message.info('Analiz işlemi devam ediyor. Lütfen daha sonra tekrar deneyin.');
    }
    setLoading(false);
    return data;
}

const BloodTest = () => {
    const [loading, setLoading] = useState(true);
    const [bloodTestData, setBloodTestData] = useState([]); // [{}]

    const {bloodTestId} = useParams();

    useEffect(() => {
        if(bloodTestId) {
            getBloodTestData(bloodTestId, setLoading).then(data => {
                setBloodTestData(data?.data);
            });
        }
    }, [bloodTestId]);

    // useEffect(() => {
    //     const wrapResultsInGroups = () => {
    //         const resultItems = document.querySelectorAll('.result-item');
    //         const referenceRanges = document.querySelectorAll('.reference-range');
    //         const interpretations = document.querySelectorAll('.interpretation');
    //
    //         for (let i = 0; i < resultItems.length; i++) {
    //             const resultGroup = document.createElement('div');
    //             resultGroup.className = 'result-group';
    //
    //             resultItems[i].parentNode.insertBefore(resultGroup, resultItems[i]);
    //             resultGroup.appendChild(resultItems[i]);
    //             resultGroup.appendChild(referenceRanges[i]);
    //             resultGroup.appendChild(interpretations[i]);
    //         }
    //     };
    //
    //     if (!loading) {
    //         wrapResultsInGroups();
    //     }
    // }, [loading]);


    const bloodTestResult = bloodTestData?.data?.result?.replace("```html", "").replace("```", "");
    console.log(bloodTestResult);

    return (
        <div className="card-container">
            <Card>
                <img src={require("../../assets/logo512.png")} alt="logo" style={{
                    width: 100,
                    height: 100,
                    margin: '0 auto',
                    marginTop: 32,
                    marginBottom: 32,
                    display: 'block'
                }}/>
                {
                    loading ? <>
                        <Spin spinning={loading} fullscreen />
                    </> : <div dangerouslySetInnerHTML={{
                        __html: bloodTestResult
                    }}></div>
                }
            </Card>
        </div>
    );
};

export default BloodTest;
